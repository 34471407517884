<template>
  <MainLayout>
    <template v-slot:navbar-btn-wrap>
      <router-link class="btn btn-edit btn_color_grey" to="WorkingTimeSetting"
        >настройка рабочего графика</router-link
      >
    </template>
    <div v-if="isDeny" class="list container radius">
      <div
        style="border-radius: 8px 8px 8px 8px; padding: 25px; color: #EB5C6D; font-size: 16px; font-weight: 500"
      >
        <span> Обратитесь к дилеру для доступа к функционалу</span>
      </div>
    </div>
    <div v-else class="list container list_radius-all">
      <div class="list__content-adding">
        <DatePeriodSelector
          :active-tab="activeTab"
          @activeTabSelect="activeTabSelect"
          :is-back="!!(selectedGroupId || selectedUserId)"
          @back="goBack"
          :title="title"
          @search="$emit('search')"
          :start-time="startTime"
          :end-time="endTime"
          @input:endTime="$emit('input:endTime', $event)"
          @input:startTime="$emit('input:startTime', $event)"
        />
        <div class="form-wrap">
          <div
            class="form card d-block"
            style="padding-top: 18px; padding-right: 25px; border-radius: 8px"
          >
            <div>
              <v-switch
                v-model="avg_toglle"
                color="#95c23d"
                label="Среднее время"
                inset
                class="mt-0"
                dense
              ></v-switch>
            </div>
            <div v-if="avg_toglle" class="d-flex ">
              <div
                style="background-color: #6D778E; color: #fff"
                class="d-flex rounded-lg mr-7 justify-space-between avg-time-block"
              >
                <div>
                  <span class="avg-time-text">Среднее рабочее время</span>
                  <span class="avg-time-number-text">{{ average_inside }}</span>
                </div>
                <div>
                  <v-img src="/img/icons/worktime-settings-icon.png"></v-img>
                </div>
              </div>
              <div
                style="background-color: #95C23D; color: #fff;"
                class="d-flex rounded-lg mr-7 justify-space-between avg-time-block"
              >
                <div>
                  <span class="avg-time-text">Среднее время открытия</span>
                  <span class="avg-time-number-text">{{ average_in }}</span>
                </div>
                <div>
                  <v-img src="/img/icons/time-open-icon.png"></v-img>
                </div>
              </div>
              <div
                style="background-color: #8471BD; color: #fff"
                class="d-flex rounded-lg mr-7 justify-space-between avg-time-block"
              >
                <div>
                  <span class="avg-time-text"> Среднее время закрытия</span>
                  <span class="avg-time-number-text">{{ average_out }}</span>
                </div>
                <div>
                  <v-img src="/img/icons/time-close-icon.png"></v-img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <slot name="default" />
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import DatePeriodSelector from "@/components/personnelControl/DatePeriodSelector";
import MainLayout from "@/components/layouts/MainLayout";
import moment from "moment";
export default {
  name: "VisitsLayouts",
  components: { MainLayout, DatePeriodSelector },
  data() {
    return {
      avg_toglle: true
    };
  },
  props: {
    isDeny: {
      type: Boolean
    },
    global_avg: {
      type: Object
    },
    title: {
      type: String
    },
    activeTab: {
      type: Number
    },
    startTime: {
      type: Number
    },
    endTime: {
      type: Number
    }
  },
  methods: {
    activeTabSelect(event) {
      this.$emit("activeTabSelect", event);
    },
    goBack() {
      if (this.selectedUserId) this.selectedUserId = null;
      else this.selectedGroupId = null;
    }
  },
  computed: {
    selectedUserId: {
      get() {
        return this.$store.getters.getSelectedVisitLogsIds.selectedUserId;
      },
      set(newValue) {
        this.$store.commit("setSelectedVisitLogsParams", {
          fieldName: "selectedUserId",
          value: newValue
        });
      }
    },
    selectedGroupId: {
      get() {
        return this.$store.getters.getSelectedVisitLogsIds.selectedGroupId;
      },
      set(newValue) {
        this.$store.commit("setSelectedVisitLogsParams", {
          fieldName: "selectedGroupId",
          value: newValue
        });
      }
    },
    average_in() {
      return this.global_avg.average_in
        ? moment
            .duration(this.global_avg.average_in, "second")
            .format("HH:mm:ss")
        : "-";
    },
    average_out() {
      return this.global_avg.average_out
        ? moment
            .duration(this.global_avg.average_out, "second")
            .format("HH:mm:ss")
        : "-";
    },
    average_inside() {
      return this.global_avg.average_inside
        ? moment
            .duration(this.global_avg.average_inside, "second")
            .format("HH:mm:ss")
        : "-";
    }
  }
};
</script>

<style scoped>
.radius {
  border-radius: 8px !important;
}
.avg-time-block {
  width: 320px;
  height: 82px;
  padding-top: 14px;
  padding-left: 27px;
  padding-right: 10px;
}
.avg-time-text {
  font-family: Inter, fantasy;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;

  color: #ffffff;
}

.avg-time-number-text {
  margin-top: 14px;
  font-family: Inter, fantasy;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 18px;
  display: flex;
  align-items: center;

  color: #ffffff;
}

.times .time-wrapper p {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: center;

  color: #515a6e;
}

.time-green .time-wrapper:first-child p:first-child {
  color: #95c23d !important;
}
.time-green .time-wrapper:last-child p:last-child {
  color: #8471bd !important;
}
</style>
