<template>
  <table class="table">
    <thead>
      <tr>
        <slot name="header">
          <th
            v-for="(head, index) in header"
            :key="index"
            :style="head.width ? 'width: ' + head.width + 'px' : ''"
          >
            <div class="d-flex align-center justify-center">
              <slot v-bind:head="head" :name="'header.' + head.value">
                {{ head.text }}
              </slot>
            </div>
          </th>
        </slot>
      </tr>
    </thead>
    <tbody>
      <fragment v-for="(item, ind) in tableData" :key="ind">
        <tr
          style="margin-top: 10px"
          class="item"
          @click="$emit('row_click', { id: item.id, item: item })"
        >
          <td v-for="(head, index) in header" :key="index">
            <slot
              :name="'item.' + head.value"
              v-bind:item="item"
              v-bind:head="head"
            >
              <div
                class="d-flex align-center justify-center"
                v-if="head.renderFunction"
                v-html="head.renderFunction(item) || '-'"
              ></div>
              <div v-else>{{ item[head.value] || "нет данных" }}</div>
            </slot>
          </td>
        </tr>
        <tr
          style="
    width: 100%;"
        >
          <td
            colspan="4"
            style="
            padding: 0 0 15px 0;
            margin:  0 0 15px 0;"
          >
            <div
              style="background-color: #EBEDF3;
              border-radius: 10px !important;
    height: 5px !important;
    min-height: 5px !important"
            >
              <div
                style="background-color: #95C23D; height: 5px;
              border-radius: 10px !important;"
                :style="{ width: `${Math.random() * 100}%` }"
              ></div>
            </div>
          </td>
        </tr>
      </fragment>

      <div
        class="d-flex align-center justify-center"
        style="text-align: center; width: 100%;"
        v-if="tableData.length === 0"
      >
        Нет данных
      </div>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "VisitTable",
  props: {
    header: {
      type: Array,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped></style>
