<template>
  <div class="work-time-wrapper">
    <div
      style="background-color: #fff;"
      class="text-align_center d-flex flex-column align-center mt-3 py-3 px-3 mb-3"
    >
      <VisitTable
        @row_click="$emit('row_click', $event.id)"
        v-bind:table-data="visitsLogs"
        v-bind:header="header"
      />
    </div>
  </div>
</template>

<script>
import VisitTable from "@/components/personnelControl/VisitTable";
import * as moment from "moment";
export default {
  name: "VisitOrgInfo",
  components: { VisitTable },
  props: {
    visitsLogs: {
      type: Array
    }
  },
  data() {
    return {
      header: [
        { text: "Отдел", value: "name" },
        {
          text: "Рабочее время",
          value: "average_inside",
          renderFunction: value =>
            value.average_inside
              ? moment
                  .duration(value.average_inside, "second")
                  .format("HH:mm:ss")
              : "нет данных"
        },
        {
          text: "Время открытия",
          value: "average_in",
          renderFunction: value =>
            value.average_in
              ? moment.duration(value.average_in, "second").format("HH:mm:ss")
              : "нет данных"
        },
        {
          text: "Время закрытия",
          value: "average_out",
          renderFunction: value =>
            value.average_out
              ? moment.duration(value.average_out, "second").format("HH:mm:ss")
              : "нет данных"
        }
      ]
    };
  }
};
</script>

<style scoped></style>
