<template>
  <div class="card" style="padding-left: 36px; padding-top: 30px;">
    <div class="d-flex flex-row align-center">
      <button v-if="isBack" @click="$emit('back')" class="btn btn-back mr-2">
        <img src="/img/icons/btn-back.svg" alt="icon" />
      </button>
      <p class="title__text">
        {{ title }}
      </p>
    </div>
    <div class="btn-sorting_wrap" style="margin-top: 30px;">
      <button
        @click="activeTabSelect(0)"
        class="btn btn-sorting"
        :class="{ active: activeTab === 0 }"
      >
        Текущий месяц
      </button>
      <button
        @click="activeTabSelect(1)"
        class="btn btn-sorting"
        :class="{ active: activeTab === 1 }"
      >
        Текущая неделя
      </button>
      <button
        @click="activeTabSelect(2)"
        class="btn btn-sorting"
        :class="{ active: activeTab === 2 }"
      >
        Сегодня
      </button>
    </div>
    <div class="filter" style="padding-left: 0">
      <div class="filter__inner d-flex">
        <ValidationDateField
          v-model="start_date"
          class="mr-5"
          label="Начало периода"
        />
        <ValidationDateField
          v-model="end_date"
          class="mr-5"
          label="Конец периода"
        />
        <button
          @click="$emit('search')"
          class="btn btn_color_white btn_results"
        >
          Показать результаты
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationDateField from "@/components/ValidationField/ValidationDateField";
import moment from "moment";
export default {
  name: "DatePeriodSelector",
  components: { ValidationDateField },
  props: {
    title: {
      type: [String, Number]
    },
    activeTab: {
      type: [Number]
    },
    isBack: {
      type: Boolean
    },
    startTime: {},
    endTime: {}
  },
  methods: {
    activeTabSelect(tab) {
      this.$emit("activeTabSelect", tab);
    }
  },
  computed: {
    start_date: {
      get() {
        return this.startTime
          ? moment(this.startTime * 1000).format("YYYY-MM-DD")
          : null;
      },
      set(newValue) {
        this.$emit(
          "input:startTime",
          newValue ? moment(newValue).unix() : null
        );
      }
    },
    end_date: {
      get() {
        return this.endTime
          ? moment(this.endTime * 1000).format("YYYY-MM-DD")
          : null;
      },
      set(newValue) {
        this.$emit("input:endTime", newValue ? moment(newValue).unix() : null);
      }
    }
  }
};
</script>

<style scoped>
.filter__inner * {
  width: 232px;
}
</style>
