<template>
  <div>
    <div v-for="(dayArrs, index) in chunkedDaysArr" :key="index">
      <div
        class="card mt-0 mb-3"
        style="padding-left: 36px; padding-top: 20px; padding-bottom: 20px"
      >
        <p
          v-if="
            weekOfMonth(dayArrs[0].day) > weekOfMonth([...dayArrs].pop().day)
          "
          class="title__text"
        >
          {{ dayArrs[0].day.format("MMMM YYYY") }} -
          {{ [...dayArrs].pop().day.format("MMMM YYYY") }}
        </p>
        <p v-else-if="index === 0" class="title__text">
          {{ dayArrs[0].day.format("MMMM YYYY") }}
        </p>
        <p
          v-if="dayArrs.length > 1"
          class="title__text"
          style="color: #95C23D; margin-top: 20px;"
        >
          Неделя {{ weekOfMonth(dayArrs[0].day) }} - Неделя
          {{ weekOfMonth([...dayArrs].pop().day) }}
        </p>
        <p v-else class="title__text" style="color: #95C23D; margin-top: 20px;">
          Неделя {{ weekOfMonth(dayArrs[0].day) }}
        </p>
      </div>
      <div
        class="work-time-grid"
        style="display: grid; grid-template-columns: repeat(7, 1fr)"
      >
        <div v-for="(day, i) in dayArrs" :key="i" class="work-time-wrapper">
          <div
            style="background-color: #fff;"
            class="text-align_center d-flex flex-column align-center mt-3 py-3 px-3 mb-3"
          >
            <span class="date-number-text">{{
              day.day.format("DD MMMM")
            }}</span>
            <p class="rounded-pill date-weak-text">
              {{ day.day.format("dd") }}
            </p>
          </div>
          <div class="d-flex flex-column justify-center work-time-info">
            <div class="text-align_center" style="margin: 18px;">
              <p class=" work-time-full-text">Рабочее время</p>
              <p class=" work-time-full-text">
                {{
                  day.visits.average_inside
                    ? moment
                        .duration(day.visits.average_inside, "second")
                        .format("HH:mm:ss")
                    : "нет данных"
                }}
              </p>
            </div>
            <div class="stick mx-5"></div>
            <div
              class="d-flex align-center justify-center"
              style="padding: 13px"
            >
              <p class="work-time-full-text-small">Открытие</p>
              <div class="circle_tab"></div>
              <p class="work-time-full-text-small">Закрытие</p>
            </div>
            <div
              class="bord-radius-8"
              style="width: calc(100% - 4px); background-color: #EBEDF3; height: 5px;"
            ></div>
          </div>
          <div
            style="display: grid; height: calc(100% - 200px);  grid-gap: 10px; margin-top: 10px"
            :style="{
              'grid-template-rows': `${Math.max(
                ...dayArrs.map(el => el.visits.inside.length),
                1
              ) *
                30 +
                25}px 22px ${Math.max(
                ...dayArrs.map(el => el.visits.outside.length),
                1
              ) *
                30 +
                75}px`
            }"
          >
            <div>
              <div class="card d-flex flex-column times time-green">
                <div>
                  <div
                    v-for="(ins, ind) in day.visits.inside"
                    :key="ind"
                    class="d-flex justify-space-between align-center time-wrapper"
                  >
                    <p>
                      {{
                        ins.range_from
                          ? moment
                              .duration(ins.range_from, "second")
                              .format("HH:mm:ss")
                          : "нет данных"
                      }}
                    </p>
                    <div class="circle_tab mx-4"></div>
                    <p>
                      {{
                        ins.range_to
                          ? moment
                              .duration(ins.range_to, "second")
                              .format("HH:mm:ss")
                          : "нет данных"
                      }}
                    </p>
                  </div>
                  <div
                    v-if="day.visits.inside.length === 0"
                    class="d-flex justify-space-between align-center time-wrapper"
                  >
                    <p>-</p>
                    <div class="circle_tab mx-4"></div>
                    <p>-</p>
                  </div>
                </div>
                <div
                  class="bord-radius-8"
                  style="width: 100%; background-color: #EBEDF3; height: 5px;"
                ></div>
              </div>
            </div>
            <div
              class="card d-flex justify-center align-center break-block break-text"
              style="margin-left: 1px; margin-right: 1px; border-radius: 50px"
            >
              Перерыв
            </div>
            <div>
              <div class="card d-flex flex-column times">
                <div>
                  <div
                    v-for="(out, ind) in day.visits.outside"
                    :key="ind"
                    class="d-flex justify-space-between align-center time-wrapper"
                  >
                    <p>
                      {{
                        out.range_from
                          ? moment
                              .duration(out.range_from, "second")
                              .format("HH:mm:ss")
                          : "нет данных"
                      }}
                    </p>
                    <div class="circle_tab mx-4"></div>
                    <p>
                      {{
                        out.range_to
                          ? moment
                              .duration(out.range_to, "second")
                              .format("HH:mm:ss")
                          : "нет данных"
                      }}
                    </p>
                  </div>
                  <div
                    v-if="day.visits.outside.length === 0"
                    class="d-flex justify-space-between align-center time-wrapper"
                  >
                    <p>-</p>
                    <div class="circle_tab mx-4"></div>
                    <p>-</p>
                  </div>
                </div>
                <div class="stick mx-4"></div>
                <div class="d-flex align-center justify-center my-2">
                  <span class="break-full-text"
                    >Всего:
                    {{
                      day.visits.average_out
                        ? moment
                            .duration(day.visits.average_out, "second")
                            .format("HH:mm:ss")
                        : "нет данных"
                    }}</span
                  >
                </div>
                <div></div>
                <div
                  class="bord-radius-8"
                  style="width: 100%; background-color: #EBEDF3; height: 5px;"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";

export default {
  name: "VisitUserInfo",
  props: {
    visitsLogs: {
      type: Array
    }
  },
  data() {
    return {
      moment: moment
    };
  },
  methods: {
    rand() {
      return Math.random() * 5;
    },
    weekOfMonth(input = moment()) {
      const prefixes = [1, 2, 3, 4, 5];

      return prefixes[0 | (moment(input).date() / 7)];
    }
  },
  computed: {
    startDate() {
      return this.$store.getters.getSelectedVisitLogsIds.startDate;
    },

    endDate() {
      return this.$store.getters.getSelectedVisitLogsIds.endDate;
    },
    chunkedDaysArr() {
      return _.chunk(this.daysArr, 7);
    },
    daysArr() {
      const resArr = [];
      const startDay = moment(this.startDate * 1000);
      const endDay = moment(this.endDate * 1000);
      while (
        !!this.startDate &&
        !!this.endDate &&
        startDay.isSameOrBefore(endDay, "day")
      ) {
        resArr.push({
          day: startDay.clone(),
          visits: this.visitsLogs.find(el =>
            startDay.clone().isSame(moment(el.day * 1000))
          ) || { inside: [], outside: [] }
        });
        startDay.add(1, "d");
      }
      return resArr;
    },
    maxVisit() {
      return Math.max.apply(null, this.visits);
    },
    maxClosess() {
      return Math.max.apply(null, this.closess);
    }
  }
};
</script>

<style scoped>
.work-time-info {
  background-color: #ffffff !important;
}

.bord-radius-8 {
  border-radius: 8px;
}

.work-time-full-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* or 150% */

  color: #515a6e;
}
.work-time-full-text-small {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */
  color: #767d92;
}

.break-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */

  color: #767d92;
}

.break-full-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */

  color: #515a6e;
}
.times {
  margin: 0 1px 0 1px;
  border-radius: 8px !important;
}

.time-wrapper {
  margin: 10px 20px;
}

.date-number-text {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */

  color: #767d92;

  border: 1px solid #ffffff;
}

.date-weak-text {
  margin-top: 10px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  text-align: center;

  color: #ffffff;
}

.rounded-pill {
  width: 118px;
  height: 22px;

  background: #6d778e;
  border-radius: 50px;
}

.stick {
  border: 2px solid #e8e8f4;
  border-radius: 5px;
}
.circle_tab {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #e8e8f4;
  margin: 0 5px 0 5px;
}
.coffee-break-tab {
  width: 175px;
  height: 22px;
  border-radius: 50px;
  background: #ffffff;
  text-align: center;
}

.times .time-wrapper p {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  text-align: center;

  color: #515a6e;
}
.work-time-grid .work-time-wrapper:first-child >>> .work-time-info {
  margin-left: 10px;
  border-radius: 8px 0 0 8px;
}
.work-time-grid .work-time-wrapper:last-child >>> .work-time-info {
  margin-right: 10px;
  border-radius: 0 8px 8px 0;
}

.work-time-grid .work-time-wrapper:first-child >>> .times {
  margin-left: 10px;
}
.work-time-grid .work-time-wrapper:last-child >>> .times {
  margin-right: 10px;
}
.work-time-grid .work-time-wrapper:first-child >>> .break-block {
  margin-left: 10px !important;
}
.work-time-grid .work-time-wrapper:last-child >>> .break-block {
  margin-right: 10px !important;
}
</style>
