<template>
  <VisitsLayouts
    :is-deny="isDeny"
    :title="curVisits.title"
    @activeTabSelect="selectTab"
    :active-tab="activeTab"
    :start-time="startDate"
    :end-time="endDate"
    @search="search"
    :global_avg="curVisits.global_avg"
    @input:endTime="setEndTime"
    @input:startTime="setStartTime"
  >
    <VisitUserInfo :visits-logs="curVisits.visitlogs" v-if="!!selectedUserId" />
    <VisitOrgGroupInfo
      :visits-logs="curVisits.visitlogs"
      @row_click="selectUser($event)"
      v-else-if="!!selectedGroupId"
    />
    <VisitOrgInfo
      v-else
      :visits-logs="curVisits.visitlogs"
      @row_click="selectGroup($event)"
    />
  </VisitsLayouts>
</template>

<script>
import VisitOrgInfo from "@/components/personnelControl/VisitOrgInfo";
import VisitsLayouts from "@/components/layouts/VisitsLayouts";
import VisitUserInfo from "@/components/personnelControl/VisitUserInfo";
import moment from "moment";
import VisitOrgGroupInfo from "@/components/personnelControl/VisitOrgGroupInfo";
import { getOrganizationsByIdRequest } from "@/helpers/api/organizations";
export default {
  name: "PersonnelControlPages",
  components: {
    VisitOrgGroupInfo,
    VisitUserInfo,
    VisitsLayouts,
    VisitOrgInfo
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.check();
  },
  data() {
    return {
      activeTab: 0,
      isDeny: false
    };
  },
  computed: {
    curVisits: {
      get() {
        return this.$store.getters.getCurrentVisits;
      }
    },
    startDate: {
      get() {
        return this.$store.getters.getSelectedVisitLogsIds.startDate;
      },
      set(newValue) {
        this.$store.commit("setSelectedVisitLogsParams", {
          fieldName: "startDate",
          value: newValue
        });
      }
    },

    endDate: {
      get() {
        return this.$store.getters.getSelectedVisitLogsIds.endDate;
      },
      set(newValue) {
        this.$store.commit("setSelectedVisitLogsParams", {
          fieldName: "endDate",
          value: newValue
        });
      }
    },
    selectedUserId: {
      get() {
        return this.$store.getters.getSelectedVisitLogsIds.selectedUserId;
      },
      set(newValue) {
        this.$store.commit("setSelectedVisitLogsParams", {
          fieldName: "selectedUserId",
          value: newValue
        });
      }
    },
    selectedGroupId: {
      get() {
        return this.$store.getters.getSelectedVisitLogsIds.selectedGroupId;
      },
      set(newValue) {
        this.$store.commit("setSelectedVisitLogsParams", {
          fieldName: "selectedGroupId",
          value: newValue
        });
      }
    }
    // getSelectedVisitLogsIds
  },
  methods: {
    check() {
      getOrganizationsByIdRequest({
        id: this.$store.getters.getCurrentOrganization
      }).then(res => {
        const isAllow = res.data.allow_visitlog_control;
        if (isAllow) this.search();
        else {
          this.isDeny = true;
        }
      });
    },
    selectGroup(groupId) {
      this.selectedGroupId = groupId;
      this.search();
    },
    selectUser(userId) {
      this.selectedUserId = userId;
      this.search();
    },
    search() {
      this.$store.dispatch("visitLogSearch");
    },
    setStartTime(time) {
      this.startDate = time;
    },
    setEndTime(time) {
      this.endDate = time;
    },
    selectTab(tab) {
      this.activeTab = tab;
      switch (tab) {
        case 0: {
          this.startDate = moment()
            .startOf("month")
            .unix();
          this.endDate = moment()
            .endOf("month")
            .unix();
          break;
        }
        case 1: {
          this.startDate = moment()
            .startOf("week")
            .unix();
          this.endDate = moment()
            .endOf("week")
            .unix();
          break;
        }
        case 2: {
          this.startDate = moment().unix();
          this.endDate = moment().unix();
          break;
        }
      }
      this.search();
    }
  },
  beforeDestroy() {
    this.selectedUserId = null;
    this.selectedGroupId = null;
    this.search();
  }
};
</script>
